import { observable, computed, action } from "mobx";
import { bind } from "decko";
import { component, initialize, inject } from "tsdi";
import { LoginStore } from "./login";
import { MumbleLinks } from "../../common/controllers/mumble-links";
import { Utilities } from "../../common/controllers/utilities";
import { MumbleLink } from "../../common/models/mumble-link";
import { MumbleUser } from "../../common/models/mumble-user";
import { User } from "../../common/models/user";
import { UsersStore } from "./users";
import { Channel } from "../../common/models/channel";

@component
export class MumbleStore {
    @inject private readonly utilities!: Utilities;
    @inject private readonly mumbleLinks!: MumbleLinks;
    @inject private readonly login!: LoginStore;
    @inject private readonly users!: UsersStore;

    @observable public channelTree: Channel;
    @observable public mumbleUsers: Map<number, MumbleUser> = new Map();
    @observable public links = new Map<string, MumbleLink>();

    @initialize
    protected async initialize(): Promise<void> {
        this.channelTree = await this.utilities.channelTree();
        const users = await this.utilities.getMumbleUsers();
        users.forEach((user) => this.mumbleUsers.set(user.id, user));
        (await this.mumbleLinks.getMumbleLinks()).forEach((link) => {
            this.links.set(link.id, link);
        });
    }

    @computed public get allLinks(): MumbleLink[] {
        return Array.from(this.links.values());
    }

    @computed public get linkableUsers(): MumbleUser[] {
        return this.allUsers.filter(this.isLinkable);
    }

    @computed public get linksThisUser(): MumbleLink[] {
        return this.allLinks.filter((link) => link.user.id === this.login.userId);
    }

    @bind public isLinkable(mumbleUser: MumbleUser): boolean {
        return (
            typeof mumbleUser.id !== "undefined" &&
            mumbleUser.id !== null &&
            (!this.allLinks.some((link) => link.mumbleId === mumbleUser.id) || this.isLinkedToThisUser(mumbleUser))
        );
    }

    @bind public isLinkedToThisUser(mumbleUser: MumbleUser): boolean {
        return this.linksThisUser.some((link) => mumbleUser.id === link.mumbleId);
    }

    @bind public isMumbleUserLinked(mumbleUser: MumbleUser): boolean {
        return this.allLinks.some((link) => mumbleUser.id === link.mumbleId);
    }

    @bind public isUserLinked(user: User): boolean {
        return this.allLinks.some((link) => user.id === link.user.id);
    }

    @bind public getUser(mumbleUser: MumbleUser): User {
        if (!this.isMumbleUserLinked(mumbleUser)) {
            return;
        }
        const userId = this.allLinks.find((link) => mumbleUser.id === link.mumbleId).user.id;
        return this.users.byId(userId);
    }

    @bind public getMumbleUser(user: User): MumbleUser {
        if (!this.isUserLinked(user)) {
            return;
        }
        const mumbleUserId = this.allLinks.find((link) => user.id === link.user.id).mumbleId;
        return this.mumbleUserById(mumbleUserId);
    }

    @bind public mumbleUserById(id: number): MumbleUser {
        return this.mumbleUsers.get(id);
    }

    @computed public get allUsers(): MumbleUser[] {
        return Array.from(this.mumbleUsers.values());
    }

    @bind @action public async link(mumbleUser: MumbleUser): Promise<void> {
        const link = await this.mumbleLinks.createMumbleLink({
            mumbleId: mumbleUser.id,
            user: { id: this.login.userId } as User,
        });
        this.links.set(link.id, link);
    }

    @bind @action public async unlink(mumbleUser: MumbleUser): Promise<void> {
        const mumbleLink = this.linksThisUser.find((link) => link.mumbleId === mumbleUser.id);
        await this.mumbleLinks.deleteMumbleLink(mumbleLink.id);
        this.links.delete(mumbleLink.id);
    }

    @bind public async toggle(mumbleUser: MumbleUser): Promise<void> {
        if (this.isLinkedToThisUser(mumbleUser)) {
            await this.unlink(mumbleUser);
            return;
        }
        await this.link(mumbleUser);
    }
}
