export const chartColors = [
    "#DB2828", // red
    "#2185D0", // blue
    "#FBBD08", // yellow
    "#21BA45", // green
    "#6435C9", // violet
    "#F2711C", // orange
    "#00B5AD", // teal
    "#A333C8", // purple
    "#B5CC18", // olive
    "#E03997", // pink
];
