import * as React from "react";
import { observer } from "mobx-react";
import { Sidebar, Dimmer, Loader } from "semantic-ui-react";
import classNames from "classnames/bind";
import { inject, external } from "tsdi";
import * as css from "./app-container.scss";
import { isProductionEnvironment } from "../../../common/utils/environment";
import { LoginStore } from "../../store/login";
import { SidebarStore } from "../../store/sidebar";
import { UsersStore } from "../../store/users";
import { AppBar } from "../app-bar/app-bar";
import { Errors } from "../errors/errors";
import { QuickList } from "../quick-list/quick-list";
import { AppSidebar } from "../sidebar/sidebar";

declare const SOFTWARE_VERSION: string;

const cx = classNames.bind(css);

@observer
@external
export class AppContainer extends React.Component<{}, undefined> {
    @inject private readonly sidebar!: SidebarStore;
    @inject private readonly login!: LoginStore;
    @inject private readonly users!: UsersStore;

    public render(): JSX.Element {
        const pageClasses = cx({
            pageSidebarActive: this.sidebar.alwaysOpen && this.login.loggedIn,
        });
        if (this.login.loggedIn && this.users.loading) {
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }
        return (
            <div>
                {this.login.loggedIn && <QuickList />}
                <Errors />
                <Sidebar.Pushable className={css.content}>
                    <AppSidebar />
                    <Sidebar.Pusher className={pageClasses}>
                        <AppBar />
                        <div className={css.container}>{this.props.children}</div>
                        {isProductionEnvironment() && <div className={css.version}>{SOFTWARE_VERSION}</div>}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </div>
        );
    }
}
