import { Column, PrimaryGeneratedColumn, Entity, ManyToOne } from "typeorm";
import { is, scope, uuid, specify } from "hyrest";
import { world, linkTelegramUser, createTelegramUser } from "../scopes";
// eslint-disable-next-line import/no-cycle
import { User } from "./user";

/**
 * A Mumble user representation in the database used for storing linkage from mumble user to database users.
 */
@Entity()
export class TelegramUser {
    @PrimaryGeneratedColumn("uuid")
    @scope(world)
    @(is().validate(uuid))
    public id?: string;

    /**
     * Unique id as generated by the mumble server.
     */
    @Column("int")
    @scope(world, createTelegramUser)
    public telegramName?: string;

    /**
     * The id of the user in the database.
     */
    @ManyToOne(() => User, (user) => user.telegramUsers)
    @is()
    @scope(world, linkTelegramUser, linkTelegramUser)
    @specify(() => User)
    public user?: User;
}
