import { Column, PrimaryGeneratedColumn, Entity, ManyToOne } from "typeorm";
import { is, scope, DataType, uuid, specify } from "hyrest";
import { world, createMumbleLink, deleteMumbleLink } from "../scopes";
// eslint-disable-next-line import/no-cycle
import { User } from "./user";

/**
 * A Mumble user representation in the database used for storing linkage from mumble user to database users.
 */
@Entity()
export class MumbleLink {
    @PrimaryGeneratedColumn("uuid")
    @scope(world, deleteMumbleLink)
    @(is().validate(uuid))
    public id?: string;

    /**
     * Unique id as generated by the mumble server.
     */
    @Column("int")
    @scope(world, createMumbleLink)
    @is(DataType.int)
    public mumbleId?: number;

    /**
     * The id of the user in the database.
     */
    @ManyToOne(() => User, (user) => user.mumbleLinks)
    @is()
    @scope(world, createMumbleLink)
    @specify(() => User)
    public user?: User;
}
