import { Cached } from "./cached";
import { Playlists } from "./playlists";
import { Tags } from "./tags";
import { MumbleLinks } from "./mumble-links";
import { Sounds } from "./sounds";
import { Tokens } from "./tokens";
import { Users } from "./users";
import { Utilities } from "./utilities";
import { Validation } from "./validation";
import { Queue } from "./queue";
import { Statistics } from "./statistics";
import { Favorites } from "./favorites";
import { TelegramUsers } from "./telegram-users";

export const allControllers = [
    Cached,
    Playlists,
    Tags,
    MumbleLinks,
    Sounds,
    Tokens,
    Users,
    Utilities,
    Validation,
    Queue,
    Statistics,
    Favorites,
    TelegramUsers,
];
